import {TokenizerAndRendererExtension, Tokens} from 'marked';

const MENTION_REGEX = /^@([a-zA-Z0-9_-]+)/;

const mention: TokenizerAndRendererExtension = {
    name: 'mention',
    level: 'inline',
    start: (src: string): number|undefined => {
        const match = src.match(/@[^\s@]/);

        if (match) {
            return match.index;
        }

        return undefined;
    },
    tokenizer(src: string): Tokens.Generic|void {
        const match = MENTION_REGEX.exec(src);

        if (match) {
            return {
                type: 'mention',
                raw: match[0],
                username: match[1]
            };
        }
    },
    renderer() {
        return 'mention';
    }
};

export default mention;
