import React, {useMemo} from 'react';
import {Divider, List, ListItem, ListItemText, Theme, Typography} from '@mui/material';
import {HeadingLevels} from '@vtsfans/marked-react/dist/ReactRenderer';
import Link from 'next/link';
import Markdown from '@vtsfans/marked-react';
import {Marked, Tokens} from 'marked';
import {makeStyles} from '@theme/makeStyles';
import mention from '@components/Markdown/NewMarkdown/extensions/mention';
import hashtag from '@components/Markdown/NewMarkdown/extensions/hashtag';
import {arrayRange} from '../../../Utils/arrayUtils';
import {ROOT_ROUTES, SITE_ROUTES} from '../../../Constants/routes';
import replaceAll from 'string.prototype.replaceall';

const useStyles = makeStyles()((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    hashtag: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    mention: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 800
    },
    listItem: {
        display: 'list-item',
        padding: 0,

        '&::marker': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize
        }
    },
    blockQuote: {
        borderLeft: '4px solid ' + theme.palette.divider,
        paddingLeft: 8
    },
    paragraph: {
        wordBreak: 'break-word'
    }
}));

type MarkdownToken = 'hr'|'code'|'codespan'|'heading'|'link'|'blockquote'|'mention'|'hashtag'|'list';

export const NON_USER_MD_TOKENS: MarkdownToken[] = ['hr', 'code', 'codespan', 'heading', 'list', 'link', 'blockquote'];

export interface NewMarkdownProps {
    content: string;
    disabledTokens?: MarkdownToken[];
    allowInternalLinks?: boolean;
    fontSize?: string;
    promoterUserId?: string;
    isInline?: boolean;
}

const instancesCache: Record<string, [Marked, Record<string, unknown>]> = {};

const initMarked = (disabledTokens: string[], isInline: boolean): [Marked, Record<string, unknown>] => {
    return useMemo(() => {
        const cacheKey = [...disabledTokens, (isInline ? 'inline-enabled' : 'inline-disabled')].sort().join('.');

        if (instancesCache[cacheKey]) {
            return instancesCache[cacheKey];
        }

        const tokenizer = disabledTokens
            // Do not disable links here if disabled
            .filter(t => !['link'].includes(t))
            .reduce((prev, curr) => {
                prev[curr] = () => undefined;

                return prev;
            }, {});

        const renderer = {};
        const extensions = [];

        if (disabledTokens.includes('codespan')) {
            renderer['codespan'] = (code: JSX.Element) => <Typography>```<br/>{code}<br/>```</Typography>;
        }

        if (!disabledTokens.includes('mention')) {
            extensions.push(mention);
        }

        if (!disabledTokens.includes('hashtag')) {
            extensions.push(hashtag);
        }

        const marked = new Marked({
            breaks: true,
            mangle: false,
            headerIds: false,
            tokenizer,
            extensions
        });

        instancesCache[cacheKey] = [marked, renderer];

        return [marked, renderer];
    }, [disabledTokens]);
};

const NewMarkdown = (props: NewMarkdownProps): JSX.Element => {
    const {content, disabledTokens: disabledTokensProps = [], allowInternalLinks = false, fontSize, promoterUserId, isInline = false} = props;
    const {classes} = useStyles();

    const disabledTokens = [...disabledTokensProps, 'html', 'image', 'table', 'tableHeader', 'tableBody', 'tableRow', 'tableCell'];
    const [marked, renderer] = initMarked(disabledTokens, isInline);

    const parsedContent = useMemo(() => isInline ? replaceAll(content, '\n', ' ') : content, [content, isInline]);

    return <Markdown
        value={parsedContent}
        breaks
        isInline={isInline}
        renderer={{
            paragraph(children: React.ReactNode) {
                return <Typography fontSize={fontSize} className={classes.paragraph}>{children}</Typography>;
            },
            strong(children: React.ReactNode) {
                return <Typography fontSize={'inherit'} component={'span'} fontWeight={800}>{children}</Typography>;
            },
            em(children: React.ReactNode) {
                return <Typography fontSize={'inherit'} component={'span'} fontStyle={'italic'}>{children}</Typography>;
            },
            del(children: React.ReactNode) {
                return <Typography fontSize={'inherit'} component={'span'} style={{textDecoration: 'line-through'}}>{children}</Typography>;
            },
            hr() {
                return <Divider/>;
            },
            heading(children: React.ReactNode, level: HeadingLevels) {
                return <Typography fontSize={'inherit'} variant={`h${level}`}>{children}</Typography>;
            },
            link(href: string, text: React.ReactNode) {
                const isInternalLink = href.startsWith('https://sugarfans.com') || href.startsWith('http://sugarfans.com') || !href.startsWith('http');
                const allowedLink = allowInternalLinks && isInternalLink;

                if (disabledTokens.includes('link') && !allowedLink) {
                    return <Typography fontSize={'inherit'} component={'span'}>{text}</Typography>;
                }

                if (!href.startsWith('http')) {
                    return <Link href={href}>
                        <Typography fontSize={'inherit'} component={'span'} className={classes.link}>{text}</Typography>
                    </Link>;
                }

                return <a href={href} target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                    <Typography fontSize={'inherit'} component={'span'} className={classes.link}>{text}</Typography>
                </a>;
            },
            defaultToken(token: Tokens.Generic) {
                if (token.type === mention.name) {
                    return <Link href={ROOT_ROUTES.USER(token.username) + (promoterUserId ? `?fid=${promoterUserId}` : '')}>
                        <Typography fontSize={'inherit'} component={'span'} className={classes.mention}>{token.raw}</Typography>
                    </Link>;
                }

                if (token.type === hashtag.name) {
                    return <Link href={SITE_ROUTES.FEED_PARAM('hashtag', token.hashtag)}>
                        <Typography fontSize={'inherit'} component={'span'} className={classes.hashtag}>{token.raw}</Typography>
                    </Link>;
                }

                return null;
            },
            space(token: Tokens.Space) {
                if (isInline) {
                    return null;
                }

                const nbSpaces = (token.raw.match(/\r?\n/g) || []).length;

                if (nbSpaces === 0) {
                    return null;
                }

                return <>
                    {arrayRange(0, nbSpaces - 2).map((i) => <br key={i}/>)}
                </>;
            },
            image(src: string, alt: string) {
                return <Typography fontSize={'inherit'}>{alt}</Typography>;
            },
            list(children: React.ReactNode, ordered: boolean) {
                return <List sx={{listStyle: ordered ? 'decimal' : 'disc', paddingLeft: '20px'}}>{children}</List>;
            },
            listItem(children: React.ReactNode[]) {
                return <ListItem className={classes.listItem}>
                    <ListItemText sx={{margin: 0, fontSize: 'inherit'}} primary={children} />
                </ListItem>;
            },
            blockquote(children: React.ReactNode) {
                return <blockquote className={classes.blockQuote}>{children}</blockquote>;
            },
            ...renderer
        }}
        instance={marked}
    />;
};

export default NewMarkdown;
