import DomainEvent, {DomainEventData} from '../../DomainEvent';
import {SugarFansEvent} from '../../EventBus';

export interface PostPurchasedEventData extends DomainEventData {
    postId: string;
}

class PostPurchasedEvent extends DomainEvent {
    constructor(postId: string) {
        super({postId}, 'Post purchased');
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.post.purchased';
    }
}

export default PostPurchasedEvent;