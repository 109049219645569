import React, {useMemo, useState} from 'react';
import {Theme, Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {makeStyles} from '@theme/makeStyles';
import {REGEX_LINE_BREAK} from '../../Constants';
import matchAll from 'string.prototype.matchall';
import {isEqual} from 'lodash';
import NewMarkdown, {NewMarkdownProps} from '@components/Markdown/NewMarkdown';

interface MarkdownProps extends Omit<NewMarkdownProps, 'content' | 'fontSize'>{
    text: string;
    readMore?: boolean;
    customParagraphFontSize?: string
    controlledReadMore?: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    classes?: {
        variables?: string,
        links?: string,
        text?: string,
        readMore?: string
    };
    cypress?: string;
    className?: string;
}

const READ_MORE_THRESHOLD = 250;
const READ_MORE_BREAK_THRESHOLD = 4;

const useStyles = makeStyles()((theme: Theme) => ({
    readMore: {
        cursor: 'pointer',
        display: 'inline-block',
        marginTop: theme.spacing(1)
    },
    listItem: {
        marginLeft: theme.spacing(4),
        '&::marker': {
            fontSize: '0.75rem'
        }
    },
    clickable: {
        cursor: 'pointer'
    }
}));

export const useReadMoreThreshold = (text: string, allowLineBreaks: boolean): number => {
    if (allowLineBreaks && text) {
        const matches: RegExpMatchArray[] = Array.from(matchAll(text, REGEX_LINE_BREAK));
        const nbBreaks = (matches || []).length;

        // If more line breaks than permitted, change threshold to show only max line breaks
        if (nbBreaks > READ_MORE_BREAK_THRESHOLD) {
            const thresholdBreakPosition = matches[READ_MORE_BREAK_THRESHOLD - 1] ? matches[READ_MORE_BREAK_THRESHOLD - 1].index : 30;

            // If line breaks are below normal threshold limit, use that instead
            return Math.min(READ_MORE_THRESHOLD, thresholdBreakPosition);
        }
    }

    return READ_MORE_THRESHOLD;
};

const Markdown = (props: MarkdownProps): JSX.Element => {
    const {text, readMore, customParagraphFontSize, controlledReadMore, classes: propClasses, cypress, className, ...rest} = props;

    const [readMoreOpen, setReadMoreOpen] = controlledReadMore || useState<boolean>(false);
    const {t} = useTranslation('post');
    const {classes, cx} = useStyles();

    const readMoreThreshold = useMemo(() => useReadMoreThreshold(text, true), [text]);
    const readMoreEnabled = readMore && text.length > readMoreThreshold;

    // PAragraph
    // <Typography color='textPrimary' display={'inherit'} variant={'body2'} style={{fontSize: customParagraphFontSize && customParagraphFontSize, wordBreak: 'break-word'}} className={propClasses && propClasses.text}>{children}</Typography>

    // Heading
    // <Typography color='textPrimary' variant={`h${level > 4 ? 4:level}` as 'h1' | 'h2' | 'h3' | 'h4'}>{children}</Typography>

    const processedText = useMemo(() => {
        if (readMoreEnabled && !readMoreOpen) {
            return text.slice(0, readMoreThreshold) + '...';
        }

        return text;
    }, [text, readMoreEnabled, readMoreOpen, readMoreThreshold]);

    // Convert line breaks
    // if (withLineBreaks) {
    //     processedText = replaceAll(processedText, /(\r\n|\r|\n)/g, '\\\n');
    // }

    return (
        <div data-cy={cypress} className={className}>
            {/* <ReactMarkdown allowedTypes={allowedTypes} unwrapDisallowed plugins={plugins} renderers={renderers}>{processedText}</ReactMarkdown>*/}
            <NewMarkdown
                content={processedText}
                fontSize={customParagraphFontSize}
                {...rest}
            />

            {readMoreEnabled && (
                <Typography aria-label='read more' variant={'body1'} component={'span'} color={'primary'} display={'block'} className={cx(propClasses && propClasses.text, classes.readMore, propClasses && propClasses.readMore)} onClick={() => setReadMoreOpen(!readMoreOpen)}>
                    <strong>
                        {readMoreOpen ? t('READ_LESS') : t('READ_MORE')}
                    </strong>
                </Typography>
            )}
        </div>
    );
};

Markdown.defaultProps = {
    hashtags: false,
    mentions: false,
    readMore: false,
    variables: false
};


const arePropsEqual = (prevProps: Readonly<MarkdownProps>, nextProps: Readonly<MarkdownProps>): boolean => {
    // Text
    if (prevProps.text !== nextProps.text) {
        return false;
    }

    if (!isEqual(prevProps.classes, nextProps.classes)) {
        return false;
    }

    // Read more
    if (prevProps.controlledReadMore && nextProps.controlledReadMore && prevProps.controlledReadMore[0] !== nextProps.controlledReadMore[0]) {
        return false;
    }

    if (prevProps.customParagraphFontSize !== nextProps.customParagraphFontSize) {
        return false;
    }

    // Read more + withLinks
    if (prevProps.readMore !== nextProps.readMore) {
        return false;
    }

    if (prevProps.allowInternalLinks !== nextProps.allowInternalLinks) {
        return false;
    }

    if (!isEqual(prevProps.disabledTokens, nextProps.disabledTokens)) {
        return false;
    }

    if (prevProps.promoterUserId !== nextProps.promoterUserId) {
        return false;
    }

    return true;
};


export default React.memo(Markdown, arePropsEqual);
