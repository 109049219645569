import BottomMenu from '@components/BottomMenu/BottomMenu';
import BottomMenuItem from '@components/BottomMenu/BottomMenuItem';
import { SelectCardModalData } from '@components/Payment/SelectCardModal';
import { ReportData } from '@components/Utils/Report';
import { useAlertContext } from '@context/AlertContext';
import { useAppContext } from '@context/AppContext';
import { useDialog } from '@context/ModalContext';
import SubscriptionManager from '@provider/Controller/subscriptions/SubscriptionManager';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionEditedMoneyWallet from 'src/EventBus/Event/Subscription/SubscriptionEditedMoneyWallet';
import SubscriptionEditedRenewal from 'src/EventBus/Event/Subscription/SubscriptionEditedRenewal';
import { UserBlockedEventData } from 'src/EventBus/Event/User/UserBlockedEvent';
import { UserSubscribedEventData } from 'src/EventBus/Event/User/UserSubscribedEvent';
import { UserUnblockedEventData } from 'src/EventBus/Event/User/UserUnblockedEvent';
import EventBus, { useEventBus, useSingleEventBus } from 'src/EventBus/EventBus';
import { useMoneyWallet, useTiers } from 'src/Services/SWR';
import { SubscribedDisableActions } from './Subscribed';

interface SubscriptionOptionsMenuProps {
    disableActions?: SubscribedDisableActions;
    subscription: SubscriptionManager;
    open: boolean;
    closeHandler: () => void;
    actionCallback?: () => void;
}

const SubscriptionOptionsMenu = (props: SubscriptionOptionsMenuProps): JSX.Element => {
    const {disableActions, subscription, open, closeHandler, actionCallback} = props;

    const { notifier } = useAppContext();
    const { t } = useTranslation('account');
    const { confirm } = useAlertContext();

    const [openReport] = useDialog<ReportData>('ReportMenu');
    const [openSelectCard] = useDialog<SelectCardModalData>('SelectCardModal');

    const {data: tiers} = useTiers(subscription && subscription.influencer && subscription.influencer.data.id);
    const {data: wallet} = useMoneyWallet();

    // Disable renewal if toggleRenewal must be enabled (others disabled) and subscription has autorenewal disabled
    const disabledRenewal = disableActions && !disableActions.toggleRenewal && !subscription.data.autoRenewal;

    useEventBus([{
        id: `block-${subscription && subscription.influencer && subscription.influencer.data.id}-refresh`,
        callback: (e: UserBlockedEventData | UserUnblockedEventData) => {
            if (e.blockedUserUsername === subscription.influencer.data.username) {
                subscription.influencer.refresh();
            }
        },
        events: ['sugarfans.user.blocked', 'sugarfans.user.unBlocked']
    }]);

    useSingleEventBus('sugarfans.user.subscribed', 'subscribed-user-refresh', (eventData: UserSubscribedEventData) => {
        if (eventData.userId === subscription.influencer.data.id) {
            subscription.influencer.refresh();
        }
    });

    if (!tiers || !subscription || !subscription.influencer || !subscription.influencer.visitorStatus) {
        return (
            <></>
        );
    }

    const user = subscription.influencer;

    const handleDecideBlock = () => {
        confirm((user.visitorStatus.blocked ? t('ARE_YOU_SURE_TO_UNBLOCK') : t('ARE_YOU_SURE_TO_BLOCK')) + ' ' + user.data.username + '?', (user.visitorStatus.blocked ? t('USER_WILL_BE_UNBLOCKED') : t('YOU_WILL_BE_ABLE_TO_UNBLOCK')), 'outlined')
            .then(() => handleBlock())
            .catch(() => {/** no-op */});
    };

    const handleBlock = () => {
        if (user.visitorStatus.blocked) {
            user.visitorStatus.setBlocked(false);

            user.unBlock()
                .then(() => actionCallback && actionCallback())
                .catch(() => {
                    user.visitorStatus.setBlocked(true);
                });
        }
        else {
            user.visitorStatus.setBlocked(true);

            user.block()
                .then(() => actionCallback && actionCallback())
                .catch(() => {
                    user.visitorStatus.setBlocked(false);
                });
        }
    };

    const handleRenewal = (renew = false, cardProviderId: string = null) => {
        return subscription.editAutoRenewal(subscription.data.id, renew, cardProviderId)
            .then(() => notifier.success(renew ? t('AUTOMATIC_RENEWAL_ENABLED') : t('AUTOMATIC_RENEWAL_CANCELLED')))
            .then(() => EventBus.dispatch(new SubscriptionEditedRenewal(subscription.data.id)))
            .then(() => subscription.refresh())
            .then(() => actionCallback && actionCallback());
    };

    const handleEditMoneyWallet = () => {
        return new Promise<void>(resolve => {
            if (subscription.data.moneyWalletId) {
                return subscription.editMoneyWallet(null).then(() => resolve());
            }
    
            return subscription.editMoneyWallet(wallet.id).then(() => resolve());
        })
            .then(() => notifier.success(subscription.data.moneyWalletId ? t('DISABLED_SUBSCRIPTION_MONEY_WALLET') : t('ENABLED_SUBSCRIPTION_MONEY_WALLET')))
            .then(() => EventBus.dispatch(new SubscriptionEditedMoneyWallet(subscription.data.id)))
            .then(() => subscription.refresh())
            .then(() => actionCallback && actionCallback());
    };

    return (
        <BottomMenu open={open} closeHandler={closeHandler}>
            {!(disableActions && disableActions.toggleRenewal) && (
                <BottomMenuItem aria-label='toggle automatic renew' title={subscription.data.tier.type !== 'freebie' ? subscription.data.autoRenewal ? t('CANCEL_AUTOMATIC_RENEW') : t('ACTIVATE_AUTOMATIC_RENEW') : ''}
                    subtitle={
                        user.visitorStatus && t('ENDS_IN', {
                            count: moment.unix(user.visitorStatus.until).diff(moment(), 'days'),
                            days: moment.unix(user.visitorStatus.until).diff(moment(), 'days')}
                        )}
                    color={'primary'}
                    disabledClick={subscription.data.tier.type === 'freebie' || disabledRenewal}
                    onClick={() => subscription.data.tier.type !== 'freebie' ? subscription.data.autoRenewal ? handleRenewal() : openSelectCard({ autoRenew: true, onSelect: (cardProvider, __) => handleRenewal(true, cardProvider), useCardText: t('USE_THIS_CARD'), customSelectCardText: t('SELECT_CARD_AUTO_RENEW'), customButtonText: t('SELECT_A_CARD'), disableAddPaymentCard: true }) : null}
                    id={subscription.data.autoRenewal ? 'cancel-renewal' : 'activate-renewal'}
                />
            )}
            {!(disableActions && disableActions.editWallet) && (
                <BottomMenuItem 
                    aria-label='edit money wallet subscription' 
                    title={subscription.data.moneyWalletId ? t('RENEW_SUBSCRIPTION_WITH_CARD') : t('RENEW_SUBSCRIPTION_WITH_WALLET')} 
                    subtitle={subscription.data.moneyWalletId ? t('CURRENT_PAYMENT_METHOD_WALLET') : t('CURRENT_PAYMENT_METHOD_CARD')} 
                    color={'primary'} 
                    onClick={() => handleEditMoneyWallet()}
                    id={subscription.data.moneyWalletId ? 'renew-with-card' : 'renew-with-wallet'}
                />
            )}
            {!(disableActions && disableActions.report) && (
                <BottomMenuItem aria-label='report user from subscribed' title={t('REPORT_USER')} color={'primary'} onClick={() => openReport({manager: user})} id={'report'} />
            )}
            {!(disableActions && disableActions.block) && (
                <BottomMenuItem aria-label='block/unblock user from subscribed' title={user.visitorStatus && user.visitorStatus.blocked ? t('UNBLOCK') : t('BLOCK')} color={'primary'} onClick={() => handleDecideBlock()} id={user.visitorStatus && user.visitorStatus.blocked ? 'unblock' : 'block'} />
            )}
        </BottomMenu>
    );
};

export default SubscriptionOptionsMenu;