import React, { useState } from 'react';
import UserManager from '../../Provider/Controller/user/UserManager';
import { DialogProps, useDialog, withDialog } from '@context/ModalContext';
import { useAppContext } from '@context/AppContext';
import { useCharge } from './ChargeModal';
import PostManager from '../../Provider/Controller/post/PostManager';
import ChatMessageManager from '../../Provider/Controller/chat/ChatMessageManager';
import { useUpdaterContext } from '@context/UpdaterContext';
import EventBus from 'src/EventBus/EventBus';
import PostPurchasedEvent from 'src/EventBus/Event/Post/PostPurchasedEvent';
import { ValidPaymentContents } from '@provider/Data/Interfaces/ChargeDataProviderInterface';
import { AuthenticatedModalData, AuthenticatedType } from '@components/Profile/Actions/AuthenticateModal';
import UserModal from '@components/Utils/UserModal';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentButton, { PaymentButtonLoading } from './PaymentButton';
import {useSafeUser} from '../../Services/SWR';
import {useAppDispatch} from '../../Redux/store';
import getChatMessage from '../../Redux/slices/chatSlice/thunks/getChatMessage';

export type HandlePay = (providerId?: string, cardId?: string) => Promise<void>

export interface MediaPaymentModalData {
    // The user instance or its ID
    user: UserManager|string,
    handlePay: HandlePay;
    price: number;
    contentType: ValidPaymentContents;
    authenticateModalType: AuthenticatedType;
}

const MediaPaymentModal = (props: DialogProps<MediaPaymentModalData>): JSX.Element => {
    const { destroyHandler, closeHandler, open, data} = props;
    const {user: userProp, handlePay: payCallback, authenticateModalType, price, contentType} = data;
    const {authenticated} = useAppContext();
    const [openAuthenticated] = useDialog<AuthenticatedModalData>('AuthenticatedModal');

    const user = useSafeUser(userProp);

    const {t} = useTranslation('paymentMethods');

    const [loading, setLoading] = useState<PaymentButtonLoading>(null);

    const createPayment = (walletId?: string, cardId?: string) => {
        setLoading(walletId ? 'wallet' : 'card');
        
        payCallback(walletId, cardId).then(() => {
            closeHandler();
        })
            .catch(e => console.warn(e))
            .finally(() => {
                setLoading(null);
            });
    };

    const handlePay = (walletId?: string) => {
        if (!authenticated) {
            openAuthenticated({user, type: authenticateModalType});
        } else {
            createPayment(walletId, null);
        }
    };

    return (
        <UserModal closedHandler={destroyHandler} user={userProp} open={open} closeHandler={closeHandler}>
            <Box pb={2} pr={2} pl={2}>
                {!authenticated ? (
                    <Button onClick={() => handlePay(null)} aria-label={'payment modal button unauthenticated'} disabled={!user}>
                        {t('LOGIN_TO_PURCHASE')}
                    </Button>
                ) : (
                    <PaymentButton
                        amount={price}
                        contentType={contentType}
                        loading={loading}
                        optionHandler={walletId => handlePay(walletId)}
                        showSum
                        disabled={!user}
                    />
                )}
            </Box>
        </UserModal>
    );
};

// Just two helper hooks to not have to use the controllers and charge hook wherever we want to open this modal
export const usePayPost = (post: PostManager): HandlePay => {
    const {controllers} = useAppContext();
    const updater = useUpdaterContext()[1];
    const pay = useCharge(() => Promise.resolve(post.data.id), post.data.id);

    return (walletId?: string, cardId?: string) => controllers.postController.buyPost(post.data.id).then(() => pay('post', post.data.price, post.data.userId, cardId, walletId).then(() => post.refresh())).then(() => EventBus.dispatch(new PostPurchasedEvent(post.data.id))).then(() => updater());
};

export const usePayChatFile = (message: ChatMessageManager): HandlePay => {
    const {controllers} = useAppContext();
    const updater = useUpdaterContext()[1];
    const pay = useCharge(() => Promise.resolve(message.data.id), message && message.data.id);

    return (walletId?: string, cardId?: string) => controllers.chatController.payMessageFile(message.data.id).then(() => pay('chat_file', message.data.file.price, message.data.userId, cardId, walletId)).then(() => message.refresh()).then(() => updater());
};

export const usePayChatFileRedux = (data: {messageId: string, price: number, userId: string}): HandlePay => {
    const {controllers} = useAppContext();
    const dispatch = useAppDispatch();
    const pay = useCharge(() => Promise.resolve(data.messageId), data.messageId);

    return (walletId?: string, cardId?: string) =>
        controllers.chatController.payMessageFile(data.messageId)
            .then(() => pay('chat_file', data.price, data.userId, cardId, walletId))
            .then(() => {
                dispatch(getChatMessage({messageId: data.messageId}));
            });
};

export default withDialog(MediaPaymentModal, 'MediaPaymentModal');
