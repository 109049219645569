import React from 'react';
import { useMediaContext } from '../../Context/MediaContext';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@theme/makeStyles';
import bound from 'src/Utils/bound';

interface StyleProps {
    size?: number;
    dotColor?: string;
    activeDotColor?: string;
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, {size = 6, dotColor, activeDotColor}) => ({
    dot: {
        borderRadius: '50%',
        width: size, height: size,
        background: dotColor || theme.palette.primary.contrastText,
        marginRight: 2,
        marginLeft: 2,
        transition: 'background 300ms, width 300ms ease-in-out 0s, height 300ms ease-in-out 0s, margin 300ms ease-in-out 0s'
    },
    medium: {
        width: size * 2/3,
        height: size * 2/3,
        marginRight: size * 1/6 + 2,
        marginLeft: size * 1/6 + 2
    },
    small: {
        width: size * 1/3,
        height: size * 1/3,
        marginRight : size * 1/3 + 2,
        marginLeft: size * 1/3 + 2
    },
    hidden: {
        width: 0,
        height: 0,
        marginRight: 0,
        marginLeft: 0
    },

    active: {
        background: activeDotColor || theme.palette.primary.main
    }
}));

const distanceClasses = [null, 'medium', 'small', 'hidden'] as const;

interface ImageSliderDotsProps extends StyleProps {
    currentDot: number;
    totalDots: number;
}

const centerDots = 3;

const Dots = (props: ImageSliderDotsProps): JSX.Element => {    
    const {totalDots, currentDot, ...styleProps} = props;
    const {classes, cx} = useStyles(styleProps);

    // Lowkey stole this algorithm from react-instagram-zoom-slider
    const centerOffset = React.useRef<number>(0);
    const slideOffset = React.useRef<number>(0);

    const currentCenterOffset = currentDot - slideOffset.current;
    if (currentCenterOffset >= 0 && currentCenterOffset < centerDots) {
        centerOffset.current = currentCenterOffset;
    } else {
        slideOffset.current = currentDot - centerOffset.current;
    }

    if (totalDots <= 1) {
        return null;
    }

    return (
        <Grid container style={{width: 'auto'}} alignItems='center'>
            {Array(totalDots).fill('').map((__,idx) => {
                const centerPage = Math.floor(centerDots / 2) + slideOffset.current;
                const distance = Math.abs(idx - centerPage);
        
                const scaledDistance = bound(distance - Math.floor(centerDots / 2), 0, 3);

                return <div key={idx} className={cx(classes.dot, idx === currentDot && classes.active || '', classes[distanceClasses[scaledDistance]] || '')} />;
            })}
        </Grid>
    );
};

export const ImageSliderDots = (props: Omit<ImageSliderDotsProps, 'totalDots' | 'currentDot'>): JSX.Element => {
    const {currentSlide, files} = useMediaContext();

    return <Dots totalDots={files.length} currentDot={currentSlide} {...props} />;
};

export default Dots;