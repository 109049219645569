import React from 'react';
import { Theme } from '@mui/material/styles';
import {makeStyles} from '@theme/makeStyles';
import {IMAGE_ASPECT_RATIO, MIN_IMAGE_ASPECT_RATIO} from '../../../Constants';
import { useThemeContext } from '../../../Theme/ThemeContext';
import PlaceholderMedia from '@components/Post/PostMedia/PlaceholderMedia';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { SubPaymentFileProps } from './SubPaymentFile';
import EyePurchaser from './EyePurchaser';

interface HiddenMediaProps extends SubPaymentFileProps {
    withPinned?: boolean;
    aspectRatio?: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
    pinned: {
        // eslint-disable-next-line custom-rules/no-hardcoded-color-use-styles
        color: '#eae2e2',
        transform: 'rotate(-50deg)',
        position: 'absolute',
        zIndex: 2,
        left: theme.spacing(1),
        top: theme.spacing(1)
    }
}));

const HiddenMedia = (props: HiddenMediaProps): JSX.Element => {
    const { withPinned, aspectRatio, ...rest } = props;
    const {isDesktop} = useThemeContext();
    const { classes } = useStyles();

    return (
        <PlaceholderMedia aspectRatio={aspectRatio ? aspectRatio : (isDesktop ? MIN_IMAGE_ASPECT_RATIO:IMAGE_ASPECT_RATIO)}>
            <div style={{position: 'relative'}}>
                {withPinned && (
                    <PushPinOutlinedIcon className={classes.pinned} />
                )}
            </div>

            <EyePurchaser {...rest} />
        </PlaceholderMedia>
    );
};

export default HiddenMedia;
