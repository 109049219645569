import withDimensions from '@components/Utils/withDimensions';
import { Box } from '@mui/material';
import React from 'react';
import {IMAGE_ASPECT_RATIO, POST_BORDER_RADIUS} from 'src/Constants';

interface PlaceholderMedia {
    width: number,
    children: JSX.Element | JSX.Element[],
    aspectRatio?: number
}

const PlaceholderMedia = (props: PlaceholderMedia): JSX.Element => {
    const {width, children, aspectRatio = IMAGE_ASPECT_RATIO} = props;

    return <Box width={width} height={width/aspectRatio} borderRadius={POST_BORDER_RADIUS}>
        {children}
    </Box>;
};

export default withDimensions(PlaceholderMedia);

export const PlaceholderMediaWithoutDims = PlaceholderMedia;