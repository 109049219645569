import React from 'react';
import UserManager from '../../../../Provider/Controller/user/UserManager';
import { Button, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import { useTranslation } from 'react-i18next';
import { SubscriptionBundlesModalData } from './SubscriptionBundlesModal';
import SubscribeMessage from './SubscribedMessage';
import {useSingleEventBus} from '../../../../EventBus/EventBus';
import Tooltip from '../../../Utils/Tooltip';
import {useAppContext} from '../../../../Context/AppContext';
import {THEMES} from '../../../../Constants';
import {useThemeContext} from '../../../../Theme/ThemeContext';
import { useDialog } from '../../../../Context/ModalContext';
import {useTiers} from '../../../../Services/SWR';
import {UserSubscribedEventData} from '../../../../EventBus/Event/User/UserSubscribedEvent';
import useCurrency from '../../../../Hooks/Utils/useCurrency';
import { AuthenticatedModalData } from '../AuthenticateModal';
import useQueryParam from 'src/Hooks/Utils/useQueryParam';

const useStyles = makeStyles()(() => ({
    subscribeButton: {
        display: 'flex',
        borderRadius: 4,
        textTransform: 'none'
    },
}));

interface UserProps {
    user: UserManager,
    variant?: 'contained' | 'outlined',
    showMessage: boolean,
    excludeOneshotPrice?: boolean,
    excludeTime?: boolean;
    children?: React.ReactNode,
    fullWidth?: boolean,
    buttonClassName?: string
    typographyClassName?: string
}

const Subscribe = (props: UserProps): JSX.Element => {
    const {
        user,
        variant = 'outlined',
        showMessage = true,
        excludeOneshotPrice = false,
        excludeTime = false, children,
        fullWidth = true,
        buttonClassName = '',
        typographyClassName = '',
        ...hoverable
    } = props;

    const {classes, cx} = useStyles();
    const {t} = useTranslation('profile');
    const [openSubscriptionBundles] = useDialog<SubscriptionBundlesModalData>('SubscriptionBundleModal');
    const [openAuthenticatedModal] = useDialog<AuthenticatedModalData>('AuthenticatedModal');
    const {authenticated, authUser, router, authReady} = useAppContext();
    const {themeName} = useThemeContext();
    const currency = useCurrency();

    const {data: tiers, loading: tiersLoading} = useTiers(user.data.id);

    useSingleEventBus('sugarfans.user.subscribed', 'subscribed-user-refresh', (eventData: UserSubscribedEventData) => {
        if (eventData.userId === user.data.id) {
            user.refresh();
        }
    });

    const [tiersWithoutOneShot, normalTiers] = React.useMemo(() => {
        if (!tiers) {
            return [[], []];
        }

        // Taking all the tiers and removing the one shot tier
        const tiersNoOneShot = tiers.filter(tier => ((!tier.data.oneShot && !tier.data.used) || !tier.data.oneShot) && tier.data.type !== 'freebie');
        const normal = tiers.filter(tier => ((tier.data.oneShot && !tier.data.used) || !tier.data.oneShot) && tier.data.type !== 'freebie');

        // Sorting tiers by price
        tiersNoOneShot.sort((a, b) => (a.data.price > b.data.price) ? 1 : ((b.data.price > a.data.price) ? -1 : 0));

        return [tiersNoOneShot, normal];
    }, [tiers, tiersLoading]);

    const isMyProfile = authenticated && user.data.id === authUser.data.id;
    const displayedTier = excludeOneshotPrice && tiersWithoutOneShot.length > 0 ? tiersWithoutOneShot[0]:normalTiers[0];

    const authenticateSuccess = (username: string) => {
        if (username !== user.data.username && !router.asPath.includes('?subscribe')) { // !isMyProfile
            router.replace(router.asPath + '?subscribe', null, {shallow: true});
        }
    };

    const open = (): boolean => {
        if (tiersLoading || !tiers || tiers.length === 0 || !authReady) {
            return false;
        }
        // Open authenticated modal directly only if the price is on the button (only 1 tier)
        if (!authenticated) {
            openAuthenticatedModal({type: 'subscribe', user, onSuccess: authenticateSuccess});
        } else if (!isMyProfile) {
            openSubscriptionBundles({ tiers: normalTiers, user: user });
        }

        return authenticated;
    };

    // Open subscribe modal if query param present
    useQueryParam('subscribe', open, [authReady]);

    React.useEffect(() => {
        if (normalTiers.length > 0 && router.query.subscribe != null && user) {
            const banned = user.data.banned && user.data.blockedContent;
            const blocked = user.visitorStatus && (user.visitorStatus.blocked || user.visitorStatus.blockedMe);
            const subbed = user.visitorStatus && user.visitorStatus.subscribed;
            if (!banned && !blocked && !subbed) {
                open();
            }
        }
    }, [normalTiers]);

    if ((!children && tiersLoading) || (tiers && tiers.length === 0)) {
        return <></>;
    }

    const showModal = normalTiers.length > 1;

    const handleDecideButtonText = () => {
        if (children) {
            return children;
        }

        if (!showModal) {
            return (`${t('SUBSCRIBE_FOR')} ${currency(displayedTier.data.price/100)}` + (excludeTime ? '': ` x ${displayedTier.data.billingInterval / 30} ${t('MONTH', { count: displayedTier.data.billingInterval/30})}`));
        }

        return t('SUBSCRIPTION_BUNDLES');
    };

    return (
        <>
            {!user.visitorStatus.subscribed && (!user.visitorStatus.blockedMe && !user.visitorStatus.blocked) && (
                <Tooltip title={t('common:USER_BANNED_WARNING', {username: user.data.username})} disabled={!user.data.banned}>
                    <Button aria-label='subscribe' disabled={user.data.banned} variant={variant} color={'primary'} fullWidth={fullWidth} className={cx(classes.subscribeButton, buttonClassName)} onClick={open} {...hoverable}>
                        <Typography
                            variant="h2"
                            // eslint-disable-next-line custom-rules/no-button-typography-color
                            color={variant === 'outlined' ? themeName === THEMES.LIGHT ? 'primary' : 'textPrimary':'secondary'}
                            className={typographyClassName}
                        >
                            {handleDecideButtonText()}
                        </Typography>
                    </Button>
                </Tooltip>
            )}

            {authenticated && showMessage && (
                <SubscribeMessage user={user} />
            )}
        </>
    );
};

export default Subscribe;
