import DomainEvent, {DomainEventData} from '../../DomainEvent';
import {SugarFansEvent} from '../../EventBus';

export interface SubscriptionEditedMoneyWalletlData extends DomainEventData {
    subscriptionId: string;
}

class SubscriptionEditedMoneyWallet extends DomainEvent {
    constructor(subscriptionId: string) {
        super({subscriptionId: subscriptionId});
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.subscription.editedMoneyWallet';
    }
}

export default SubscriptionEditedMoneyWallet;