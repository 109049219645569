import React, { useEffect, useState } from 'react';
import {makeStyles} from '@theme/makeStyles';
import {bool, instanceOf, string} from 'prop-types';
import { useTranslation } from 'react-i18next';
import {CardActions, Theme, Tooltip, Typography} from '@mui/material';
import SubscribeIcon from '../../../../icons/subscribe/glyphs-subscription.svg';
import SubscribeTime from '../../../../icons/subscribe/glyphs-time-black.svg';
import moment from 'moment';
import UserManager from '../../../../Provider/Controller/user/UserManager';
import SVGImage from '../../../Utils/SVGImage';
import SubscriptionOptionsMenu from '@components/Profile/Menu/Subscriptions/SubscriptionOptionsMenu';
import { useAppContext } from '@context/AppContext';
import SubscriptionManager from '@provider/Controller/subscriptions/SubscriptionManager';
import { useSingleEventBus } from 'src/EventBus/EventBus';

const useStyles = makeStyles()((theme: Theme) => ({
    subscribedText: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: '6px',
        marginTop: '1.125rem'
    },
    subscribedTextBundle: {
        padding: '0.438rem 0.563rem 0.25rem 0.625rem',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '6px',
        marginTop: '1.125rem'
    },
    subscribedTimeIcon: {
        display: 'flex',
        marginLeft: 'auto !important'
    },
    subscriptionIcon: {
        marginRight: theme.spacing(1/2),
        width: 15,
        height: 15,
        display: 'flex'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

interface SubscribeMessageProps {
    user: UserManager;
    isBundle: boolean;
    className?: string;
}

const SubscribeMessage = (props: SubscribeMessageProps): JSX.Element => {
    const {classes, cx} = useStyles();
    const {user, isBundle, className} = props;
    const {t} = useTranslation('profile');

    const {controllers} = useAppContext();
    
    const [open, setOpen] = useState(false);
    const [subscription, setSubscription] = useState<SubscriptionManager>();

    const fetchSubscription = () => {
        controllers.subscriptionController.all({
            page: 1,
            perPage: 15,
            filters: {influencerId: user.data.id, status: 'active'}
        })
            .then(sub => {
                if (sub.data[0]) {
                    setSubscription(sub.data[0]);
                }
            });
    };

    // Fetch subscription on mount
    useEffect(() => {fetchSubscription();}, []);

    // Fetch subscription when subscribed
    useSingleEventBus('sugarfans.user.subscribed', 'sub-message-subscribed-refresh-subs', () => {fetchSubscription();});

    return (
        user.visitorStatus.subscribed && (
            <>
                {subscription && (
                    <SubscriptionOptionsMenu open={subscription && open} closeHandler={() => setOpen(false)} subscription={subscription} />
                )}

                <CardActions disableSpacing className={cx(isBundle ? classes.subscribedTextBundle : classes.subscribedText, className)}>
                    <SVGImage fill={'background-paper'} svg={SubscribeIcon} removeMargin width={13} height={13} className={classes.subscriptionIcon} />

                    <Typography variant="h2" color='primary'>
                        <strong>{t('YOU_ARE_SUBSCRIBED')}</strong>
                    </Typography>

                    <SVGImage fill={'background-paper'} color={'text-secondary'} svg={SubscribeTime} width={13} height={14} className={classes.subscribedTimeIcon} />

                    <Tooltip title={t('MANAGE_SUBSCRIPTION')} arrow>
                        <Typography variant="h2" color={isBundle ? 'textPrimary' : 'textSecondary'} style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setOpen(true)} aria-label={'manage subscription'}>
                            {moment.unix(user.visitorStatus.until).diff(moment(), 'days') === 0 ? (
                                <strong>{t('LESS_THAN_A_DAY')}</strong>
                            ) : (
                                <strong>{t('DAY_LEFT', {count: moment.unix(user.visitorStatus.until).diff(moment(), 'days'), days: moment.unix(user.visitorStatus.until).diff(moment(), 'days')})}</strong>
                            )}
                        </Typography>
                    </Tooltip>
                </CardActions>
            </>
        )
    );
};

SubscribeMessage.propTypes = {
    user: instanceOf(UserManager).isRequired,
    isBundle: bool,
    className: string
};

SubscribeMessage.defaultProps = {
    isBundle: false,
    className: ''
};

export default SubscribeMessage;