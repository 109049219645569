import { useTranslation } from 'react-i18next';
import {Button, Grid, Theme, Typography } from '@mui/material';
import {makeStyles} from '@theme/makeStyles';
import SVGImage from '../SVGImage';
import React from 'react';
import File from '../../../Provider/Data/Model/File';
import {UploaderFile} from '../../../Context/UploaderContext';
import LockIcon from '@icons/post/icon-lock.svg';
import LockIconOpen from '@icons/post/icon-lock-open.svg';
import CamIcon from '@icons/post/cam-icon.svg';
import VideoIcon from '@icons/post/video-icon.svg';
import moment from 'moment';
import {useThemeContext} from '@theme/ThemeContext';
import Subscribe from '@components/Profile/Actions/Subscribe';
import { HoverableData } from '../../../Hooks/Binders/useHover';
import UserManager from '@provider/Controller/user/UserManager';
import useTax from '../../../Hooks/Utils/useTax';

const useStyles = makeStyles()((theme: Theme) => ({
    containerDisplayIcon: {
        position: 'relative',
        width: 'fit-content'
    },
    infoDisplay: {
        width: 'fit-content',
        border: `2px solid ${theme.palette.primary.main}`,
        borderBottom: 0,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        backgroundColor: 'rgba(0, 0, 0, 0.30);',
        opacity: 0.9,
        padding: theme.spacing(1),
        minWidth: '9rem',
        marginBottom: theme.spacing(-.5) + '!important',
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(.5)
        }
    },
    content: {
        [theme.breakpoints.down('md')]: {
            '& *': {
                fontSize: '0.75rem'
            }
        }
    },
    alignText: {
        alignSelf: 'center',
        marginRight: theme.spacing(.5),
        marginLeft: theme.spacing(.5)
    },
    positionedLocks: {
        width: 'fit-content',
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        top: '-1.5rem'
    },
    locks: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    counter: {
        marginBottom: theme.spacing(.5),
        marginTop: theme.spacing(.5)
    },
    // It's used because we have a lot of parts on the web where we use the <subscribe> component and has diff colours.
    forceDark: {
        color: `${theme.palette.common.black} !important`
    },
    button: {
        height: 33,
        [theme.breakpoints.down('md')]: {
            height: 30
        }
    }
}));

export interface SubPaymentFileProps {
    files: UploaderFile[] | File[];
    className?: string;
    children?: JSX.Element;
    paymentCallback?: () => void;
    user: UserManager;
    type?: 'post' | 'chat';
    price?: number;
    hoverableData?: HoverableData;
}

const SubPaymentFile = (props: SubPaymentFileProps): JSX.Element => {
    const { files, className = '', children, paymentCallback, type = 'post', user, price, hoverableData} = props;
    const { classes, cx } = useStyles();
    const { t } = useTranslation(['account', 'post']);
    let imagesCount = 0;
    let videosCount = 0;
    let videoDuration = null;

    const { isDesktop } = useThemeContext();
    const [tax] = useTax('ppc');

    files.forEach((file: UploaderFile | File) => {
        if (file instanceof File && file.deleted) {
            return;
        }

        if (file.type.includes('image')) {
            imagesCount++;
        } else {
            videosCount++;
            if (file instanceof File && file.duration && file.duration > 0) {
                videoDuration = moment.utc(file.duration*1000).format(file.duration/60 >= 60 ? 'HH:mm:ss' : 'mm:ss');
            }
        }
    });

    const handleDecideButtonRender = (): JSX.Element => {
        if (price > 0) {
            return <Button aria-label={`unlock-${type}`} className={classes.button} color='primary' variant='contained' onClick={paymentCallback}><strong>{t('post:UNLOCK_FOR', { price: tax(price / 100, false) })}</strong></Button>;
        }

        if (type === 'chat') {
            return <Button aria-label='subscribe user' className={classes.button} fullWidth variant='contained' onClick={paymentCallback}><strong>{t('SUBSCRIBE')}</strong></Button>;
        }

        return <Subscribe user={user} showMessage={false} buttonClassName={classes.button} fullWidth={false} variant='contained' excludeOneshotPrice typographyClassName={classes.forceDark} excludeTime>{t('post:SUBSCRIBE')}</Subscribe>;
    };

    if (imagesCount > 0 || videosCount > 0) {
        return (
            <Grid container direction='column' justifyContent={'center'} className={cx(classes.containerDisplayIcon, className)}>
                <div className={classes.infoDisplay}>
                    <div className={classes.positionedLocks}>
                        <div className={classes.locks}>
                            <SVGImage svg={hoverableData && hoverableData[1] ? LockIconOpen : LockIcon} width={21} height={18} color={'secondary'} removeStroke removeMargin />
                        </div>
                    </div>

                    {imagesCount > 0 && (
                        <Grid container xs item justifyContent={'center'} alignItems={'center'} alignContent={'center'} className={classes.counter}>
                            <SVGImage svg={CamIcon} width={14} height={17} color={'secondary'} removeMargin removeStroke />


                            <Typography variant={isDesktop ? 'h2' : 'h3'} color={'white'} className={classes.alignText}>
                                <strong>{t('IMAGES_COUNT', {count: imagesCount})}</strong>
                            </Typography>
                        </Grid>
                    )}

                    {videosCount > 0 && (
                        <Grid container xs item justifyContent={'center'} alignItems={'center'} alignContent={'center'} className={classes.counter}>
                            <SVGImage svg={VideoIcon} width={14} height={17} color={'secondary'} removeMargin removeStroke />

                            <Typography variant={isDesktop ? 'h2' : 'h3'} color={'white'} className={classes.alignText}>
                                <strong>{`${t('VIDEOS_COUNT', {count: videosCount})} ${videoDuration ? `(${videoDuration})` : ''}`}</strong>
                            </Typography>
                        </Grid>
                    )}
                </div>

                {children ? children : handleDecideButtonRender()}
            </Grid>
        );
    }

    return <></>;
};

export default SubPaymentFile;
