import {TokenizerAndRendererExtension, Tokens} from 'marked';

const HASHTAG_REGEX = /^#([A-Za-z0-9]+)/;

const hashtag: TokenizerAndRendererExtension = {
    name: 'hashtag',
    level: 'inline',
    start: (src: string): number|undefined => {
        const match = src.match(/#[^\s#]/);

        if (match) {
            return match.index;
        }

        return undefined;
    },
    tokenizer(src: string): Tokens.Generic|void {
        const match = HASHTAG_REGEX.exec(src);

        if (match) {
            return {
                type: 'hashtag',
                raw: match[0],
                hashtag: match[1]
            };
        }
    },
    renderer() {
        return 'hashtag';
    }
};

export default hashtag;
