import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@theme/makeStyles';
import { useThemeContext } from '@theme/ThemeContext';
import React from 'react';
import useHover from 'src/Hooks/Binders/useHover';
import Eye from '../../../../public/static/img/eye.svg';
import SVGImage from '../SVGImage';
import SubPaymentFile, { SubPaymentFileProps } from './SubPaymentFile';
import useSwiperClick from '../../../Hooks/Binders/useSwiperClick';

interface EyePurchaserProps extends SubPaymentFileProps {
    paymentBox?: boolean;
    blurred?: boolean;
    className?: string;
    withoutEye?: boolean;
    cypress?: string;
}

type EyeSize = 'sm'|'md'|'lg';

const sizeMapping: Record<EyeSize, number> = {
    sm: 170,
    md: 250,
    lg: 400
};

const useStyles = makeStyles<EyeSize>()((theme: Theme, size: EyeSize) => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.tipsBackground.background,
        borderRadius: 8,
        cursor: 'pointer'
    },
    containerBlurred: {
        backgroundColor: 'inherit',
        position: 'absolute',
        top: 0
    },
    itemBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    eyeContainer: {
        width: '80%',
        maxWidth: sizeMapping[size],
        margin: '0 auto',
        opacity: 0.7,
        textAlign: 'center',
        height: '60%',
        maxHeight: '40%'
    },
}));

const EyePurchaser = (props: EyePurchaserProps): JSX.Element => {
    const {paymentBox = true, blurred = false, paymentCallback, className, withoutEye = false, cypress, ...rest} = props;
    const {isDesktop} = useThemeContext();
    const {cx, classes} = useStyles(isDesktop ? 'lg' : (blurred ? 'md' : 'sm'));

    const hoverableData = useHover();
    const [hoverable] = hoverableData;

    const swiperProps = useSwiperClick(() => paymentCallback && paymentCallback(), 'eye click purchase');

    return (
        <Grid container flexDirection={'column'} justifyContent={'space-evenly'} className={cx(classes.container, blurred && classes.containerBlurred, className)} {...hoverable} {...swiperProps} data-cy={cypress}>
            {!withoutEye && (
                <Grid item container justifyContent={'center'} className={classes.eyeContainer}>
                    <SVGImage svg={Eye} color={'secondary'} fill={blurred ? 'logo-light' : 'logo'} removeStroke removeMargin width={'100%'} height={'100%'} />
                </Grid>
            )}

            {paymentBox && (
                <Grid item className={classes.itemBox}>
                    <SubPaymentFile hoverableData={hoverableData} paymentCallback={paymentCallback} {...rest} />
                </Grid>
            )}
        </Grid>
    );
};

export default EyePurchaser;