import DomainEvent, {DomainEventData} from '../../DomainEvent';
import {SugarFansEvent} from '../../EventBus';

export interface SubscriptionEditedRenewalData extends DomainEventData {
    subscriptionId: string;
}

class SubscriptionEditedRenewal extends DomainEvent {
    constructor(subscriptionId: string) {
        super({subscriptionId: subscriptionId});
    }

    public eventName(): SugarFansEvent {
        return 'sugarfans.subscription.editedRenewal';
    }
}

export default SubscriptionEditedRenewal;